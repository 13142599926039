$icons-font: "icons";

i[class^="icon-"]:before, i[class*=" icon-"]:before {
  font-family: icons !important;
  font-style: normal;
  font-weight: normal !important;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

$icons-map: (
        "arrow-down": "\f101",
        "google": "\f102",
        "insta": "\f103",
        "smile": "\f104",
);

.icon-arrow-down:before {
  content: map-get($icons-map, "arrow-down");
}
.icon-google:before {
  content: map-get($icons-map, "google");
}
.icon-insta:before {
  content: map-get($icons-map, "insta");
}
.icon-smile:before {
  content: map-get($icons-map, "smile");
}
