// CONFIGURATE DOCUMENT

$fontsize: (
  main: (
    sm: (
      fs: 12px,
      lh: 14px,
    ),
    md: (
      fs: 25px,
      lh: 1.2em,
    ),
  ),
  title: (
    sm: (
      fs: 12px,
      lh: 14px,
    ),
    md: (
      fs: 30px,
      lh: 1.2em,
    ),
  ),
);

$margin: (
  sm: 1,
);

:root {
  font-size: 16px;
  --base-space: 8px;
  --color-text: black;
  --color-link: black;
  --color-bg: #ffffe6;
  --sm-padding: 35px;
  --md-padding: 70px;
}

// __________________________________________________________ //

// FONTS

$fonts: (
  main: (
    name: sfcompactrounded-medium,
    fallback: "Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif",
    withFile: true,
  ),
  black: (
    name: sfcompactrounded-black,
    fallback: "Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif",
    withFile: true,
  ),
  icons: (
    name: icons,
    fallback: "Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif",
    withFile: true,
  ),
);

// __________________________________________________________ //

// BREAKPOINTS

$breakpoints: (
  xs: 0px,
  sm: 576px,
  md: 1024px,
);

// __________________________________________________________ //

// TRANSITION

$transition-property-default: all;
$transition-duration-default: 0.2s;
$transition-timing-default: cubic-bezier(0.7, 0, 0.3, 1);
$transition-delay-default: 0s;

// __________________________________________________________ //

// FREE CONFIG

$color-green: #009640;
$color-beige: #ffffe6;
$color-red: #ff6476;
