:root{
  scroll-behavior: smooth !important;
}
*, html, body, main {
  scroll-behavior: smooth !important;
}

html, body{
  position: fixed;
  height: 100%;
  width: 100%;
  overflow: hidden;
  left: 0;
  top:0;
}

main{
  height: 100%;
  overflow-y: scroll;
  position: fixed;
  scroll-snap-type: y mandatory;
  display: flex;
  flex-direction: column;
}

.grid{
  display: grid;
}

.rounded-title{
  background-color: $color-green;
  color: $color-beige;
  padding: 0.25em 0.75em;
  display: inline-block;
  border-radius: 0.5em;
  text-transform: uppercase;
  @include font(black);
  font-size: 1em;
  &.red{
    background-color: $color-beige;
    color: $color-red;
  }
}

h1, h2, h3, h4, h5, h6{
  font-weight: normal;
  margin-bottom: 0;
}

.txt-small{
  font-size: 0.75em;
}