//———————————— MENU  ———————————— //

#menu {
  height: auto;
  width: 100%;
  background-color: $color-beige;
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  z-index: 9;
  border-bottom: 8px solid $color-green;
  padding: calc(var(--sm-padding) / 2) var(--sm-padding);
  transform: translateY(0%);
  transition: transform $transition-duration-default $transition-timing-default;
  @include mq(md) {
    height: 200px;
    padding: calc(var(--md-padding) / 2) var(--md-padding);
  }

  &.slide-out {
    transform: translateY(-100%);
    transition: transform $transition-duration-default $transition-timing-default;
  }
}

.logo {
  height: auto;
  width: 35%;
  @include mq(md){
    width: auto;
    height: 100%;
  }
  overflow: hidden;
}

.logo img {
  width: 100%;
  object-fit: contain;
  @include mq(md){
    width: auto;
    height: 100%;
  }
}

.second-logo {
  margin-left: auto;
  @include mq(md){
    order: unset;
  }
}

.lang-menu {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: auto;
  @include mq(md){
    margin-left: calc(var(--md-padding) / 2);

  }

  li {
    margin-bottom: 2px;
  }

  a {
    display: block;
    padding: 0 0.75em;
    border-radius: 0.5em;
    text-transform: uppercase;
    color: $color-green;
    background-color: $color-beige;
    border-color: $color-green;
    border: 2px solid;
    @include transition(all, 0.25s);

    &.active {
      @include transition(all, 0.25s);
      color: $color-beige;
      background-color: $color-green;
      border-color: $color-green;
    }

    @include notouch() {
      &:hover {
        @include transition(all, 0.25s);
        color: $color-beige;
        background-color: $color-green;
        border-color: $color-green;
      }
    }
  }
}

//———————————— GLOBAL SECTION  ———————————— //


section {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 100%;
  min-height: calc(var(--vh) * 100);
  height: auto;
  text-align: center;
  scroll-snap-align: start;
  box-sizing: border-box;
  padding: var(--sm-padding);
  @include mq(md) {
    padding: var(--md-padding);
  }
}


//———————————— IMAGE SECTION  ———————————— //

.section.image {
  padding: 0;
  position: relative;
  background-color: transparent;
  flex-direction: column;
  padding-top: 100px;
  overflow: hidden;
  @include mq(md){
    padding-top: 200px;
  }

  picture {
    position: absolute;
    z-index: -1;
    display: block;
    height: calc(100% + 150px);
    width: 100%;
    pointer-events: none;
    user-select: none;
    @include mq(md){
      height: calc(100% + 200px);
    }

    img {
      height: 100%;
      max-width: 100%;
      display: block;
      object-fit: cover;
    }
  }

  .nocolorlogo {
    width: 65vw;
    max-width: 840px;
    @include mq(md){
      width: 45vw;
      max-width: 840px;
    }
  }

  .section-img--text-content {
    color: white;
    margin-top: auto;
    text-transform: uppercase;
    margin-bottom: 50px;
    h1 {
      @include font(black);
      font-size: 7vw;
      line-height: 1em;
      @include mq(md) {
        font-size: 4vw;

      }
    }

    h2 {
      line-height: 1em;
      font-size: 5.7vw;
      @include mq(md) {
        font-size: 3.2vw;
      }
    }
    @include mq(md){
      margin-bottom: 0;
    }
  }

  .scroll-down-arrow{
    @include font(icons);
    margin-top: auto;
    margin-bottom: var(--sm-padding);
    transition: all .2s ease-out;
    @include notouch(){
      &:hover{
        color: $color-beige;
        background-color: $color-red;
      }
    }
    @include mq(md){
      margin-bottom: var(--md-padding);
    }

  }

  @keyframes scale1 {
    0% {transform: scale(1.1);
      box-shadow:0 25px 25px rgba(0, 0, 0, 0.4);
    }
    50% {transform: scale(1.3);
      box-shadow:0 25px 25px rgba(0, 0, 0, 0.6)
    }
    100% {transform: scale(1.1);
      box-shadow:0 25px 25px rgba(0, 0, 0, 0.4)
    }
  }
}


  //———————————— ABOUT SECTION ———————————— //

  #about {
    min-height: max-content;
    background-color: transparent;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-areas: "a"
                       "b"
                       "c";
    @include mq(md) {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: min-content 1fr;
      grid-template-areas: "a b"
                       "c b";

    }

    .row {
      height: 100%;
    }

    .rounded-title {
      grid-area: a;
      max-width: max-content;
      margin: 0 auto;
      margin-bottom: var(--sm-padding);
      @include mq(md) {
        margin-bottom: 0;
      }
    }

    img {
      grid-area: c;
      padding: 6vh;
      display: block;
      object-fit: contain;
      margin: 0 auto;
      width: 100%;
      max-width: 750px;
    }

    .text-row {
      grid-area: b;
      color: $color-green;
      text-align: left;
      font-size: 3.5vw;
      line-height: 1.15em;
      @include mq(md) {
        padding: 0 10%;
        font-size: 1.4vw;
      }

      p {
        margin-bottom: 1em;
      }
    }

    .img-row {
      display: flex;
      flex-direction: column;
      align-items: center;

      .rounded-title {
        display: inline-block;
        max-width: max-content;
      }

      img {

      }
    }

  }


  section:last-of-type {
    scroll-snap-stop: always !important;
  }

  footer {
    background-color: $color-green;
    padding: var(--sm-padding);
    grid-template-columns: repeat(2, 1fr);
    @include mq(md) {
      padding: var(--md-padding);
      grid-template-columns: repeat(2, 1fr);
    }
  }


  //———————————— MENU SECTION ———————————— //

  .section.menu {
    display: block;
    padding: calc(var(--md-padding) / 2.5);
    background-color: $color-green;
    position: relative;
    @include mq(md){
    padding: calc(var(--md-padding));
    }
    .menu-section--buttons {
      position: absolute;
      width: 100%;
      left: 0;
      @include mq(md) {
        width: auto;
        left: var(--md-padding);
        top: var(--md-padding);
      }

      div {
        margin-bottom: calc(var(--md-padding) / 2);
      }
    }

    .download {
      @include font(icons);
      @include transition(all, 0.25s);
      @include notouch() {
        &:hover {
          background-color: $color-red;
          color: $color-beige;
          @include transition(all, 0.25s);
        }
      }
    }

    .menu-img {
      height: 100%;
      padding: 2rem 0;
      @include mq(md){
        padding: 0;
      }
    }
  }


  //———————————— GALLERY SECTION ———————————— //
  .gallery-section{
    min-height: max-content;
    @include mq(md){
      min-height: calc(var(--vh) * 100);
    }
  }
  .gallery {
    display: grid;
    width: 100%;
    max-width: 1400px;
    margin: 0 auto;
    grid-template-columns: 1fr; /* 2 colonnes de même taille */
    grid-template-rows: auto auto; /* 2 lignes de même taille */
    height: auto;
    gap: 10px; /* Espacement entre les images, ajustez selon besoin */
    @include mq(md) {
      height: 100vh;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr; /* 2 lignes de même taille */
      grid-gap: calc(var(--md-padding) / 2);
      padding: var(--md-padding);
    }

    .grid-item {
      aspect-ratio: 1/1;
      @include mq(md) {
        aspect-ratio: unset;
      }
      position: relative;
      overflow: hidden;
    }

    .grid-item picture {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;

      img {
        height: 100%;
        max-height: 100%;
        object-fit: cover;
        border-radius: 0.5em;
      }
    }
  }


  //———————————— FOOTER SECTION ———————————— //

  footer {
    scroll-snap-align: end !important;
    color: $color-beige;
    grid-gap: var(--sm-padding);
    grid-template-columns: repeat(1,1fr);
    height: calc(var(--vh) * 100);
    @include mq(md) {
      grid-template-columns: repeat(2,1fr);
      grid-gap: var(--md-padding);
    }
  }

  .footer-logo {
    width: 50%;
    margin-bottom: 1em;
  }

  .footer-info {
    padding: 1em 0;
    border-top: 2px solid;
    border-bottom: 2px solid;

    figure img{
      height: 1em;
      width: auto;
    }
    figure{
      display: inline-block;
      margin-bottom: 1em;
      position: relative;
      top: 0.15em;
      margin-right: 2px;
    }
    p {
      margin-bottom: 1em;
      &:first-of-type{
        margin-bottom: 0;
      }

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  .footer-socials {
    @include font(icons);
    display: flex;
    margin-top: 1em;
    align-items: center;
    justify-content: flex-end;

    svg {
      height: 1em;

      path {
        fill: $color-beige;
      }
    }

    a {
      font-size: 2em;
      display: block;
      margin-left: 0.5em;
      color: $color-beige;
    }

    a:last-of-type {
      margin-top: 0.15em;
    }
  }

  #map {
    height: 100%;
  }